// material-ui
import React from "react";
import { Stack, Box, Grid, Typography, CardMedia } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
import { useDispatch, useSelector } from "store";
import { getPlayerBalance, getUsersByID } from "store/slices/user";

import useAuth from "hooks/useAuth";
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const { logout, user } = useAuth();
  const dispatch = useDispatch();
  const { playerBalance } = useSelector((state) => state.user);
  const [data, setData] = React.useState();

  const handleLogout = async () => {
    try {
      await logout();
    } catch (err) {
      console.error(err);
    }
  };

  React.useEffect(() => {
    setData(playerBalance);
    getUsersByID(user);
  }, [playerBalance]);

  React.useEffect(() => {
    dispatch(getPlayerBalance(user));
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px !important",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          sx={{ ml: 1 }}
        >
          <CardMedia
            component="img"
            sx={{
              width: "20px",
            }}
            image={
              "https://mickthomson-buck.sgp1.cdn.digitaloceanspaces.com/username.webp"
            }
          />
          <Typography variant="h5" align="right">
            {user?.username}
          </Typography>
        </Stack>
        <LogoSection />
        <Stack
          direction="row"
          spacing={0}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid zeroMinWidth sx={{ mr: 1, mt: 0 }}>
            <Stack direction="column" spacing={0.5}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  minWidth: 90,
                  height: 30,
                  pr: "8px",
                  pl: "8px",

                  borderRadius: "64px",
                  backgroundImage:
                    "linear-gradient(#83782f, rgb(52 52 52)), linear-gradient(180deg, #ffe75f -30%, #ffd400 22%, #787500 72%, #997c00 112%)",
                }}
              >
                <CardMedia
                  component="img"
                  sx={{
                    borderRadius: "8px",
                    width: "20px",
                  }}
                  image={
                    "https://mickthomson-buck.sgp1.cdn.digitaloceanspaces.com/coin2.webp"
                  }
                />
                <Typography
                  variant="h4"
                  align="right"
                  type="number"
                  style={{ color: "#fff" }}
                >
                  {data} บาท
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            <CardMedia
              component="img"
              sx={{ borderRadius: "8px", width: "32px" }}
              onClick={handleLogout}
              image={
                "https://mickthomson-buck.sgp1.cdn.digitaloceanspaces.com/logout.webp"
              }
            />
          </Grid>
        </Stack>
      </Grid>
    </>
  );
};

export default Header;
