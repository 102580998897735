// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import axiosex from "axios";
import { dispatch } from "../index";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  gameListUrl: [],
  bannerList: [],
  wheelSetting: [],
  wheelPoint: [],
  friendInvite: [],
  userAvailablePromotion: [],
  withdrawSetting: [],
  bankList: [],
  transferBankList: [],
  websiteSetting: [],
  transactionHistory: [],
  withdrawHistory: [],
  playerLoginLink: [],
  playerBalance: [],
  tmnData: [],
  cashbackSetting: [],
  cashback: [],
  inviteSetting: [],
  betflixStatus: [],
  totalUsers: [],
  singleUsers: [],
  agentInfo: [],
  agentฺBalance: [],
  singleAdmin: [],
  singlePromo: [],
  todayPromotionReceived: [],
  allPromotionReceived: [],
  allPromo: [],
  singleCreditCode: [],
  allCreditCode: [],
  todayUsers: [],
  byIdTransDW: [],
  allTransDW: [],
  allAdmin: [],
  usersS1: [],
  usersS2: [],
  followers: [],
  friendRequests: [],
  friends: [],
  gallery: [],
  posts: [],
  detailCards: [],
  simpleCards: [],
  profileCards: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getWheelSettingSuccess(state, action) {
      state.wheelSetting = action.payload;
    },

    getWheelPointSuccess(state, action) {
      state.wheelPoint = action.payload;
    },

    getGameListUrlSuccess(state, action) {
      state.gameListUrl = action.payload;
    },

    getFriendInviteSuccess(state, action) {
      state.friendInvite = action.payload;
    },

    getUserAvailablePromotionSuccess(state, action) {
      state.userAvailablePromotion = action.payload;
    },
    getWithdrawSettingSuccess(state, action) {
      state.withdrawSetting = action.payload;
    },

    getBankListSuccess(state, action) {
      state.bankList = action.payload;
    },

    getTransferBankListSuccess(state, action) {
      state.transferBankList = action.payload;
    },

    getTodayPromotionReceivedSuccess(state, action) {
      state.todayPromotionReceived = action.payload;
    },

    getAllPromotionReceivedSuccess(state, action) {
      state.allPromotionReceived = action.payload;
    },

    getWebsiteSettingSuccess(state, action) {
      state.websiteSetting = action.payload;
    },

    getBannerListSuccess(state, action) {
      state.bannerList = action.payload;
    },

    getTransactionHistorySuccess(state, action) {
      state.transactionHistory = action.payload;
    },

    getWithdrawHistorySuccess(state, action) {
      state.withdrawHistory = action.payload;
    },

    getTMNDataSuccess(state, action) {
      state.tmnData = action.payload;
    },

    getCashbackSettingSuccess(state, action) {
      state.cashbackSetting = action.payload;
    },

    getCashbackSuccess(state, action) {
      state.cashback = action.payload;
    },

    getInviteSettingSuccess(state, action) {
      state.inviteSetting = action.payload;
    },

    getPlayerLoginLinkSuccess(state, action) {
      state.playerLoginLink = action.payload;
    },

    getPlayerBalanceSuccess(state, action) {
      state.playerBalance = action.payload;
    },

    // GET USERS STYLE 1

    getBetflixStatus(state, action) {
      state.betflixStatus = action.payload;
    },

    getAgentInfoSuccess(state, action) {
      state.agentInfo = action.payload;
    },

    getAgentBalanceSuccess(state, action) {
      state.agentBalance = action.payload;
    },

    getAllAdminSuccess(state, action) {
      state.allAdmin = action.payload;
    },

    getUsersListStyle1Success(state, action) {
      state.usersS1 = action.payload;
    },

    getTotalUsersSuccess(state, action) {
      state.totalUsers = action.payload;
    },

    getPromoByIDSuccess(state, action) {
      state.singlePromo = action.payload;
    },

    getAllPromoSuccess(state, action) {
      state.allPromo = action.payload;
    },

    getCreditCodeByIDSuccess(state, action) {
      state.singleCreditCode = action.payload;
    },

    getAllCreditCodeSuccess(state, action) {
      state.allCreditCode = action.payload;
    },

    getUsersByIDSuccess(state, action) {
      state.singleUsers = action.payload;
    },

    getAdminByIDSuccess(state, action) {
      state.singleAdmin = action.payload;
    },

    getTodayUsersSuccess(state, action) {
      state.todayUsers = action.payload;
    },

    getAllTransactionDWSuccess(state, action) {
      state.allTransDW = action.payload;
    },

    getByIdTransactionDWSuccess(state, action) {
      state.byIdTransDW = action.payload;
    },

    // GET USERS STYLE 2
    getUsersListStyle2Success(state, action) {
      state.usersS2 = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.followers = action.payload;
    },

    // FILTER FOLLOWERS
    filterFollowersSuccess(state, action) {
      state.followers = action.payload;
    },

    // GET FRIEND REQUESTS
    getFriendRequestsSuccess(state, action) {
      state.friendRequests = action.payload;
    },

    // FILTER FRIEND REQUESTS
    filterFriendRequestsSuccess(state, action) {
      state.friendRequests = action.payload;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.friends = action.payload;
    },

    // FILTER FRIENDS
    filterFriendsSuccess(state, action) {
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.gallery = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.posts = action.payload;
    },

    // EDIT COMMENT
    editCommentSuccess(state, action) {
      state.posts = action.payload;
    },

    // ADD COMMENT
    addCommentSuccess(state, action) {
      state.posts = action.payload;
    },

    // ADD REPLY
    addReplySuccess(state, action) {
      state.posts = action.payload;
    },

    // LIKE POST
    likePostSuccess(state, action) {
      state.posts = action.payload;
    },

    // LIKE COMMENT
    likeCommentSuccess(state, action) {
      state.posts = action.payload;
    },

    // LIKE REPLY
    likeReplySuccess(state, action) {
      state.posts = action.payload;
    },

    // GET DETAIL CARDS
    getDetailCardsSuccess(state, action) {
      state.detailCards = action.payload;
    },

    // FILTER DETAIL CARDS
    filterDetailCardsSuccess(state, action) {
      state.detailCards = action.payload;
    },

    // GET SIMPLE CARDS
    getSimpleCardsSuccess(state, action) {
      state.simpleCards = action.payload;
    },

    // FILTER SIMPLE CARDS
    filterSimpleCardsSuccess(state, action) {
      state.simpleCards = action.payload;
    },

    // GET PROFILE CARDS
    getProfileCardsSuccess(state, action) {
      state.profileCards = action.payload;
    },

    // FILTER PROFILE CARDS
    filterProfileCardsSuccess(state, action) {
      state.profileCards = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getWheelSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.ufavip688.vip/setting/wheelsetting`
      );
      dispatch(slice.actions.getWheelSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWheelPoint(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.ufavip688.vip/wheel/point/${id}`
      );
      dispatch(slice.actions.getWheelPointSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGameListUrl() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/gamelogin/getlisturl`
      );
      dispatch(slice.actions.getGameListUrlSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFriendInvite(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/agentbalance/invitesummaryclient/${id}`
      );
      dispatch(slice.actions.getFriendInviteSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserAvailablePromotion(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/usersystem/getavailablepromotion/${id.id}`
      );
      dispatch(slice.actions.getUserAvailablePromotionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/setting/withdraw`
      );
      dispatch(slice.actions.getWithdrawSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBankList() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.doozy8.co/bank`);
      dispatch(slice.actions.getBankListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTranferBankList() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/8hwe8vhevv/banklist`
      );
      dispatch(slice.actions.getTransferBankListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTodayPromotionReceived(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/usersystem/checkreceivedpromotion/${id.id}`
      );
      dispatch(
        slice.actions.getTodayPromotionReceivedSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPromotionReceived(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/usersystem/checkallreceivedpromotion/${id.id}`
      );
      dispatch(
        slice.actions.getAllPromotionReceivedSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBannerList() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/setting/banner`
      );
      dispatch(slice.actions.getBannerListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWebsiteSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/setting/websetting`
      );
      dispatch(slice.actions.getWebsiteSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTMNData() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.doozy8.co/bank/tmnfront`);
      dispatch(slice.actions.getTMNDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerLoginLink(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/gamelogin/getlogin/${id.id}`
      );
      dispatch(slice.actions.getPlayerLoginLinkSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTransactionHistory(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/userbalance/history/${id}`
      );
      console.log(response.data.data);
      dispatch(slice.actions.getTransactionHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawHistory(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/userbalance/withdrawhistory/${id}`
      );
      console.log(response.data.data);
      dispatch(slice.actions.getWithdrawHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerBalance(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/userbalance/balance/${id.id}`
      );
      dispatch(slice.actions.getPlayerBalanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBetflixStatus() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.bfx.fail/v4/status`);
      dispatch(slice.actions.getBetflixStatusSuccess(response.data.status));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPromo() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.doozy8.co/promotion`);
      dispatch(slice.actions.getAllPromoSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCashbackSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/setting/cashback`
      );
      dispatch(slice.actions.getCashbackSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCashback(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/agentbalance/cashbackclientcheck/${id.id}`
      );
      dispatch(slice.actions.getCashbackSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInviteSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/setting/invite`
      );
      dispatch(slice.actions.getInviteSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgentInfo() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.doozy8.co/agentinfo`);
      dispatch(slice.actions.getAgentInfoSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgentBalance() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.doozy8.co/agentbalance`);
      dispatch(slice.actions.getAgentBalanceSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromoByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/promotion/${id.id}`
      );
      dispatch(slice.actions.getPromoByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllCreditCode() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.doozy8.co/creditcode`);
      dispatch(slice.actions.getAllCreditCodeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCreditCodeByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/creditcode/${id.id}`
      );
      dispatch(slice.actions.getCreditCodeByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/usersystem/${id.id}`
      );
      dispatch(slice.actions.getUsersByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/189a1sf898f/${id.id}`
      );
      dispatch(slice.actions.getAdminByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTotalUsers() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.doozy8.co/totalusers");
      dispatch(slice.actions.getTotalUsersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTodayUsers() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.doozy8.co/todayusers");
      dispatch(slice.actions.getTodayUsersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersListStyle1() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.doozy8.co/usersystem");
      dispatch(slice.actions.getUsersListStyle1Success(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllAdmin() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.doozy8.co/189a1sf898f");
      dispatch(slice.actions.getAllAdminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTransactionDW() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.doozy8.co/8hwe8vhevv");
      dispatch(slice.actions.getAllTransactionDWSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByIdTransactionDW(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.doozy8.co/8hwe8vhevv/${id}`
      );
      dispatch(slice.actions.getByIdTransactionDWSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersListStyle2() {
  return async () => {
    try {
      const response = await axios.get("/api/user-list/s2/list");
      dispatch(slice.actions.getUsersListStyle2Success(response.data.users_s2));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFollowers() {
  return async () => {
    try {
      const response = await axios.get("/api/followers/list");
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterFollowers(key) {
  return async () => {
    try {
      const response = await axios.post("/api/followers/filter", { key });
      dispatch(slice.actions.filterFollowersSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFriendRequests() {
  return async () => {
    try {
      const response = await axios.get("/api/friend-request/list");
      dispatch(slice.actions.getFriendRequestsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterFriendRequests(key) {
  return async () => {
    try {
      const response = await axios.post("/api/friend-request/filter", { key });
      dispatch(
        slice.actions.filterFriendRequestsSuccess(response.data.results)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFriends() {
  return async () => {
    try {
      const response = await axios.get("/api/friends/list");
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterFriends(key) {
  return async () => {
    try {
      const response = await axios.post("/api/friends/filter", { key });
      dispatch(slice.actions.filterFriendsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGallery() {
  return async () => {
    try {
      const response = await axios.get("/api/gallery/list");
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPosts() {
  return async () => {
    try {
      const response = await axios.get("/api/posts/list");
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editComment(key, id) {
  return async () => {
    try {
      const response = await axios.post("/api/posts/editComment", { key, id });
      dispatch(slice.actions.editCommentSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addComment(postId, comment) {
  return async () => {
    try {
      const response = await axios.post("/api/comments/add", {
        postId,
        comment,
      });
      dispatch(slice.actions.addCommentSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addReply(postId, commentId, reply) {
  return async () => {
    try {
      const response = await axios.post("/api/replies/add", {
        postId,
        commentId,
        reply,
      });
      dispatch(slice.actions.addReplySuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function likePost(postId) {
  return async () => {
    try {
      const response = await axios.post("/api/posts/list/like", { postId });
      dispatch(slice.actions.likePostSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function likeComment(postId, commentId) {
  return async () => {
    try {
      const response = await axios.post("/api/comments/list/like", {
        postId,
        commentId,
      });
      dispatch(slice.actions.likeCommentSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function likeReply(postId, commentId, replayId) {
  return async () => {
    try {
      const response = await axios.post("/api/replies/list/like", {
        postId,
        commentId,
        replayId,
      });
      dispatch(slice.actions.likeReplySuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDetailCards() {
  return async () => {
    try {
      const response = await axios.get("/api/details-card/list");
      dispatch(slice.actions.getDetailCardsSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterDetailCards(key) {
  return async () => {
    try {
      const response = await axios.post("/api/details-card/filter", { key });
      dispatch(slice.actions.filterDetailCardsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSimpleCards() {
  return async () => {
    try {
      const response = await axios.get("/api/simple-card/list");
      dispatch(slice.actions.getSimpleCardsSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterSimpleCards(key) {
  return async () => {
    try {
      const response = await axios.post("/api/simple-card/filter", { key });
      dispatch(slice.actions.filterSimpleCardsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfileCards() {
  return async () => {
    try {
      const response = await axios.get("/api/profile-card/list");
      dispatch(slice.actions.getProfileCardsSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterProfileCards(key) {
  return async () => {
    try {
      const response = await axios.post("/api/profile-card/filter", { key });
      dispatch(slice.actions.filterProfileCardsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
