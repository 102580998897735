import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

// member routing หน้าลูกค้า

const DashboardAnalytics = Loadable(
  lazy(() => import("views/dashboard/Analytics"))
);
const DashboardPlay = Loadable(lazy(() => import("views/dashboard/Play")));
// widget routing
const DashboardHome = Loadable(lazy(() => import("views/dashboard/Home")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard/home",
      element: <DashboardHome />,
    },
    {
      path: "/dashboard/analytics",
      element: <DashboardAnalytics />,
    },
    {
      path: "/dashboard/play",
      element: <DashboardPlay />,
    },
  ],
};

export default MainRoutes;
